<template>
  <div id="TestPage">
    <!-- Top Panel w/breacrumb -->
    <div v-if="courseObj && user" class="jumbotron jumbotron-fluid texture texture-green sub-header header-padding" :style="{ backgroundImage: `linear-gradient(180deg, rgba(65, 65, 65, 0.9) 0%, rgba(65, 65, 65, 0.9) 100%), url('${SPENV.S3_URL}/images/software-jumbotrons/${softwareId}.jpg')` }">
      <div class="container">
        <h1 class="display-4 mt-2">
          Diagnostic Page
        </h1>
        <p class="lead mb-5" />
        <div class="mt-4">
          <p>Verify video playback by viewing the Lesson below</p>
        </div>
      </div>
    </div>
    <!-- Video Container -->
    <div v-if="courseObj" class="container course">
      <div class="row mb-4 no-gutters course-content">
        <div class="video-layout col">
          <div class="video-container" :class="(isTheaterModeToggled ? 'col-lg-12' : 'col-lg-8')">
            <div>
              <Player :theater-mode="isTheaterModeToggled" :modal-content="modalContent" />
            </div>
            <div class="row no-gutters course-controls">
              <div class="col video-controls">
                <div class="layout-toggle d-none d-lg-block">
                  <a class="video-mode" href="javascript:void(0)" data-video-toggle-theater-list-class="lessons-list-theater"
                     data-video-toggle="true" @click="toggleTheaterMode">
                    <span class="label">CHANGE LAYOUT</span> <i class="layout-icon layout-compact" />
                  </a>
                </div>
                <div v-if="user.is_logged_in" class="layout-toggle d-none d-lg-block">
                  <a class="video-mode" data-video-toggle-theater-list-class="lessons-list-theater" href="javascript:void(0)"
                     data-video-toggle="true" @click="setVideoType">
                    <span class="label">CHANGE FORMAT</span> <i class="layout-icon layout-compact" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="user.is_logged_in" class="info-container" :class="(isTheaterModeToggled ? 'col-lg-7' : 'col-lg-8')">
            <div>
              <ul class="nav nav-tabs-material" role="tablist">
                <li class="nav-item">
                  <a v-if="courseObj.show_partfiles" class="nav-link" :class="{'active show': tab === 'files'}"
                     href="javascript:void(0)" @click="tab = 'files'">Exercise files</a>
                </li>
              </ul>
              <div class="tab-content">
                <div v-if="courseObj.show_partfiles" class="tab-pane" :class="{'active show': tab === 'files'}">
                  <a class="btn btn-link" href="javascript:void(0)" @click="getPartfilesByCourseId(courseObj.id)"><font-awesome-icon icon="cloud-download-alt" /> Download Files</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Player from '@/components/Player';

export default {
  name: 'TestPage',
  components: { Player },
  data () {
    return {
      breadcrumb: [],
      isTheaterModeToggled: false,
      modalContent: '',
      tab: 'files'
    };
  },
  computed: {
    courseObj () {
      return (this.$store.getters['software/getCourseBySlug'](this.getStaticSlugs()));
    },
    softwareName () {
      return (this.$store.getters['software/getSoftwareName'](this.getStaticSlugs()));
    },
    softwareId () {
      return (this.$store.getters['software/getSoftwareId'](this.getStaticSlugs()));
    },
    location () {
      return window.location;
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.$store.dispatch('software/loadCourseBySlug', this.getStaticSlugs());
    },
    /**
     * Returns a constant for the slugs we want this page locked into
     *
     * @return {object}
     */
    getStaticSlugs () {
      const staticSlugs = {
        course: 'introduction-to-solidworks-tutorials-2019',
        lessonid: 31210,
        software: 'solidworks'
      };
      return staticSlugs;
    },
    /**
     * Toggle auto play in DB if logged in and state if not jhamm 5/16/18
     * @return {void}
     */
    toggleAutoplay () {
      this.user.settings.autoplay = !this.user.settings.autoplay;
      this.$store.dispatch('user/updateAutoplaySetting', this.user.settings.autoplay);
    },
    /**
     * Toggle video playback type
     * @param {string} video type
     * @return {void}
    */
    setVideoType () {
      const oppositeVideoType = { 'html5': 'flash', 'flash': 'html5' };
      // Flip the video type according to the opposite key above
      this.user.settings.video_type = oppositeVideoType[this.user.settings.video_type];
      this.$store.dispatch('user/setVideoType', this.user.settings.video_type)
        .then(
          response => {
            window.location.reload(true);
          },
          error => {
            console.log(error);
          }
        );
    },
    /**
     * Toggle theater video mode jhamm 5/18/18
     * @return {void}
    */
    toggleTheaterMode () {
      this.isTheaterModeToggled = !this.isTheaterModeToggled;
    },
    /**
     * Get the partfiles url for a course and do download
     *
     * @param {int} courseId
     * @return {void}
     */
    getPartfilesByCourseId (courseId) {
      var downloadWindow = window.open('', '_blank');
      axios.get(this.SPENV.APP_URL + '/api/v2/course/' + courseId + '/partfiles')
        .then(function (response) {
          downloadWindow.location = response.data.url;
        });
    }
  }
};
</script>

<style scoped>
</style>
